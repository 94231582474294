// Border size
$border-width-thin: 1px;
$border-width-thick: 2px;
$border-radius-default: 5px;
$border-radius-round: 50%;
$border-radius-large: 30px;
$border-radius-xlarge: 50px;
$border-radius-xxlarge: 70px;
$border-radius-xxxlarge: 100px;

$border-progressbar-thick: 3px;

// Size
$size-zero: 0px;
$size-xxxsmall: 5px;
$size-xxsmall: 10px;
$size-xsmall: 15px;
$size-small: 20px;
$size-xmedium: 25px;
$size-xxmedium: 30px;
$size-medium: 35px;
$size-large: 40px;
$size-xlarge: 50px;
$size-xxlarge: 70px;
$size-xxxlarge: 85px;
$size-xxxgrand: 185px;

$img-regular-height: 179px;
$img-status-height: 160px;

$title-container-height: 56px;
$title-line-height: 24px;

$terms-msg-height: 203px;
$terms-btns-height: 235px;

$status-msg-height: 95px;
$status-details-height: 175px;

$review-cardname-height: 90px;

$input-field-width-full: 100%;
$input-field-container-height: 97px;
$info-page-input-field-container-height: 51px;
$input-element-height: 52px;
$input-field-error-container-height: auto;

$radio-btn-left-margin: 88px;

$dialog-content-item-divider-width: 100%;
$modal-header-height: 51px;

$size-field-max: 300px;
$size-max: 1200px;
$size-min: 845px;

$standard-page-banner-height: 200px;
$standard-page-banner-height-2: 90px;
$standard-page-banner-height-3: 160px;
$standard-page-banner-height-start: 240px;
$standard-page-banner-width: 450px;
$standard-page-banner-width-2: 200px;
$standard-page-banner-width-3: 240px;
$standard-page-banner-width-start: 285px;
$standard-page-banner-margin: 120px;

$confirmation-page-banner-height: 160px;
$confirmation-page-banner-width: 240px;

$conditions-page-body-height: 231px;
$cob-page-body-height: 250px;

$cta-button-width: 232px;
$cta-button-height: 56px;

$error-page-banner-height: 165px;
$error-page-back-button-width: 198px;
$error-page-back-button-height: 48px;
$error-page-text-width: 315px;

/**
 * Breakpoints
 */
$bp-small: 24em;
$bp-small-2: 29.75em;
$bp-small-3: 39.8em;
$bp-med: 46.8em;
$bp-med-2: 48em;
$bp-large: 50em;
$bp-large-2: 66em;
$bp-xl: 73em;
$bp-xxl: 89em;
$bp-xxxl: 93em;
