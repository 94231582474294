@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "./assets/css/scss/abstracts.scss";

html {
  font-family: "Heebo", sans-serif;
  width: 100%;
  background-color: $background-grey;
}

body {
  font-family: "Heebo", sans-serif;
  background-color: $background-grey;
}

.psm-resp-login-container {
  @media only screen and (min-width: $screen-md) {
    width: 400px;
  }

  @media only screen and (max-width: $screen-md) {
    margin-top: 15px !important;
  }
}

@layer components {

  .m-container {
    @media only screen and (min-width: $screen-md) {
      @apply flex flex-col w-full p-7;
    }

    @media only screen and (max-width: $screen-md) {
      @apply flex flex-col w-full p-2;
    }
  }

  .pillar-box-drag-div {
    @apply max-w-full block border border-gray-300 m-4;
    @apply bg-white rounded-xl overflow-hidden;

    span:last-child {
      @apply text-gray-700 focus:outline-black text-sm md:text-lg;
      @apply font-sans subpixel-antialiased font-semibold;
    }

    span:first-child {
      @apply text-gray-50 font-sans subpixel-antialiased font-semibold;
    }

    .chip-div {
      @apply rounded-full flex justify-center w-8 mr-2 shadow-2xl cursor-default;
    }

  }

  .box-shadowed-rounded {
    @apply w-full rounded-md shadow-md items-center justify-center;
    @apply bg-white flex font-sans subpixel-antialiased p-4;
  }

  .error-text-box {
    @apply bg-red-100 bg-opacity-50 text-red-900 p-2 text-center rounded-md;
    @apply ring ring-red-900 ring-opacity-40 ring-offset-2;
  }

  .container-border {
    @apply border-b-2 border-gray-200;
  }

  .text-title-alignment {
    @apply flex items-center;
  }

  .home-card {
    @apply shadow-lg bg-white border-b-8 border-blue-400 mb-4;
  }

  .text-title {
    @apply text-3xl p-4 font-sans subpixel-antialiased font-bold text-gray-600;
  }

  .text-form-title {
    @apply text-sm font-sans subpixel-antialiased font-bold text-gray-600;
  }

  .text-body {
    @apply text-xs font-sans subpixel-antialiased text-gray-400;
  }

  .pillar-box-section {
    @apply container mx-auto grid gap-2 grid-cols-2 p-4 xl:px-80 lg:px-40 md:px-20;
  }

  .pillar-btn-box {
    @apply flex items-center justify-center w-full h-40 md:h-52;
    @apply text-gray-50 text-2xl md:text-3xl focus:outline-black;
    @apply rounded-md shadow-2xl font-sans subpixel-antialiased font-semibold;
    text-shadow: 2px 1px 2px black;
  }

  .form-field-item {
    @apply flex flex-col;
  }

  .highlight-yellow {
    @apply bg-yellow-300 hover:bg-yellow-pillarhover;
  }

  .highlight-red {
    @apply bg-red-700 hover:bg-red-pillarhover;
  }

  .highlight-green {
    @apply bg-green-pdpf hover:bg-green-pillarhover;
  }

  .highlight-blue {
    @apply bg-blue-pdpf hover:bg-blue-pillarhover;
  }

  .footer-note {
    @apply text-sm text-gray-400;
  }

  .card-actions {
    @media only screen and (min-width: $screen-md) {
      @apply flex gap-4 p-2;
    }

    @media only screen and (max-width: $screen-md) {
      @apply grid grid-rows-2 grid-cols-2 gap-2 p-2;
    }
  }

  .comments-card {
    @apply text-base subpixel-antialiased pb-4;

    .comment-body {
      @apply bg-gray-200 bg-opacity-50 rounded-lg shadow-sm leading-tight p-2;
    }

    .comment-footer {
      @apply flex flex-row;

      .comment-icon-favorite {
        @apply text-sm text-blue-500 font-medium subpixel-antialiased w-4 pt-2.5 cursor-pointer;
        @apply hover:text-blue-800;
      }

      .comment-icon-erase {
        @apply text-sm text-red-600 font-medium subpixel-antialiased w-4 pt-2.5 cursor-pointer;
        @apply hover:text-red-800;
      }

      .comment-erase {
        @apply text-xs text-red-600 font-medium subpixel-antialiased pt-3 pr-3 cursor-pointer;
        @apply hover:text-red-800;
      }

      .comment-favorite {
        @apply text-xs text-blue-500 font-medium subpixel-antialiased pt-3 pr-3 cursor-pointer;
        @apply hover:text-blue-800;
      }

      .comment-date {
        @apply text-xs text-gray-400 subpixel-antialiased pt-3;
      }

    }
  }
}

:host ::ng-deep {

  .mat-card .mat-card-content p {
    font-family: "Heebo", sans-serif;
  }

  button {
    color: #616161;
  }

}

ul {
  list-style-type: none;
}

.card-margin {
  margin: 12px 0 12px;
}

.text-side-margin-medium {
  margin: 0 10px 0;
}

.text-header {
  font-weight: 500;
  font-size: 26px;
  font-family: "Heebo", sans-serif;
  color: $pdpf-text-color;
}

.text-subtitle {
  font-size: 12px;
  font-family: "Heebo", sans-serif;
  color: $pdpf-subtext-color;

  p {
    font-size: 14px;
    font-family: "Heebo", sans-serif;
    color: $pdpf-subtext-color;
  }
}

.card-order-avatar {
  background-image: url('assets/images/imgs/clipboard-list.svg');
  background-size: cover;
}

.order-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.order-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.banner {
  margin: 0px 30px 30px;
  height: 150;
  max-height: 150px;
  max-width: 400px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 12px;
}

.opt-content {
  font-family: "Heebo", sans-serif;
  font-size: 110%;
  height: 100%;
  width: 100%;
}

.opt-content-header {
  font-weight: bold;
  font-size: 140%;
  overflow-wrap: anywhere;
  font-family: "Heebo", sans-serif;
  height: 100%;
  width: 100%;
}

.box-list {
  border: solid 1px #ccc;
  min-height: 60px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}

.box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
}

.toolbar {
  background-color: $pdpf-blue;
  color: #fff;
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
}

.mat-list-base .mat-list-item {
  color: #fff;
  font-size: 14px;
}

.form-field-extended {
  width: 100%;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.mat-button .mat-button-focus-overlay {
  background-color: #005587;
}

.mat-button {
  color: #0075be;
  font-weight: 500;
}

mat-icon {
  color: #0D47A1;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  .card-container > *:not(.circle-link),
  .terminal {
    width: 100%;
  }

  .card:not(.highlight-card) {
    height: 16px;
    margin: 8px 0;
  }

  .card.highlight-card span {
    margin-left: 72px;
  }
}
