@import "./colors";
@import "./sizing";
@import "./spacing";
@import "./grid";

.psm-resp-container {
  @media only screen and (min-width: $screen-md){
    width: $screen-md;
  }

  @media only screen and (max-width: $screen-md){
    width: 100%;
  }
}

.psm-md-container {
  @media only screen and (min-width: $screen-md){
    margin-top: 215px;
  }
}
