@import "colors";

@keyframes textFieldDefaultToInFocus {
  0% {
    background-color: $input-field-underline-empty-not-focused;
  }
  100% {
    background-color: $input-field-underline-focused;
  }
}

@keyframes textFieldDefaultToErrorState {
  0% {
    background-color: $input-field-underline-empty-not-focused;
  }
  100% {
    background-color: $error-state;
  }
}

@keyframes textFieldFocusedToFilledValid {
  0% {
    background-color: $input-field-underline-focused;
  }
  100% {
    background-color: $input-field-underline-filled;
  }
}
