// Colours
$pdpf-blue: #0d47a1;
$pdpf-red: #ed1c34;
$pdpf-dark-red: #c81414;
$pdpf-pink: #ff1744;
$pdpf-black: #000000;
$dark-grey: #333333;
$grey: #757575;
$light-grey: #cacaca;
$lighter-grey: #fafafa;
$medium-light-grey: #979797;
$medium-grey: #9b9b9b;
$white: #ffffff;
$dark-blue: #005587;
$light-blue: #bedceb;
$lighter-blue: #e3edf3;
$negative: #d40c13;
$pdpf-text-color: #495057;
$pdpf-subtext-color: #6c757d;
$background-grey: #f8f9fa;

// Background Colours
$color-background-default: $white;
$color-background-alt: $pdpf-blue;
$color-background-alt2: $dark-blue;
$color-background-alt3: $lighter-grey;
$color-background-grey: $grey;
$color-background-negative: $negative;
$color-background-light-blue: $light-blue;

// Primary Button
$color-background-primary-button-default: $pdpf-blue;
$color-background-primary-button-hover: $dark-blue;
$color-background-primary-button-disabled: #ececee;
$color-background-primary-button-alt-default: $white;
$color-background-primary-button-alt-hover: $dark-blue;
$color-background-primary-button-alt-disabled: $light-grey;

// Secondary Button
$color-background-secondary-button-default: $white;
$color-background-secondary-button-hover: $dark-blue;
$color-background-secondary-button-disabled: $white;
$color-background-secondary-button-alt-default: transparent;
$color-background-secondary-button-alt-hover: $dark-blue;
$color-background-secondary-button-alt-disabled: #ececee;
$color-secondary-button-disabled: $grey;

// Bubbles
$color-background-action-bubble: $pdpf-blue;
$color-background-attention-bubble: $light-blue;
$color-background-celebration-bubble: $pdpf-blue;
$color-background-list-bubble: $lighter-grey;
$color-background-full-width-bubble: $lighter-blue;

// Binary Buttons
$color-background-binary-button-default: $white;
$color-background-binary-button-hover: $dark-blue;
$color-background-binary-button-select: $dark-blue;
$color-background-binary-button-active: $pdpf-blue;
$color-background-binary-button-disabled: $light-grey;

$color-background-tab-default: $white;
$color-background-tab-hover: $dark-blue;
$color-background-tab-select: $dark-blue;
$color-background-tab-active: $pdpf-blue;
$color-background-tab-disabled: $light-grey;

// Banner
$color-background-banner: $dark-blue;

// Cell
$color-dropdown-selected: $dark-blue;

// Border Colors
$color-border: $light-grey;
$color-border-default: $light-grey;
$color-border-inverse: $white;
$color-border-error: $negative;
$color-border-focus: $pdpf-blue;
$color-divider: $medium-light-grey;

// Text Colors
$color-text-default: $dark-grey;
$color-text-alt: $grey;
$color-text-inverse: $white;
$color-text-link: $pdpf-blue;
$color-text-link-inverse: $white;
$color-text-error: $negative;
$color-text-label: $grey;
$color-text-label-hint: $grey;

// Primary Button Text
$color-text-primary-button-default: $white;
$color-text-primary-button-inverse-default: $pdpf-blue;
$color-text-primary-button-disabled: $dark-grey;
$color-text-primary-button-inverse-disabled: $dark-grey;

$color-text-secondary-button-default: $pdpf-blue;
$color-text-secondary-button-inverse-default: $white;
$color-text-secondary-button-disabled: $pdpf-blue;
$color-text-secondary-button-inverse-disabled: $medium-grey;

$color-text-only-button-default: $pdpf-blue;
$color-text-only-button-inverse-default: $white;
$color-text-only-button-disabled: $medium-grey;
$color-text-only-button-inverse-disabled: $light-grey;

$color-text-action-bubble: $white;
$color-text-attention-bubble: $dark-grey;
$color-text-celebration-bubble: $white;

$checkbox-box-color: $light-grey;
$checkbox-checked-color: $dark-blue;
$input-field-underline-empty-not-focused: #d9dce1;
$input-field-underline-focused: #005587;
$input-field-underline-filled: #0079C1;
$error-state: #c81414;
